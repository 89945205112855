
import { Component, Vue } from 'vue-property-decorator';
import TransactionsList from '@/components/depot/TransactionsList.vue';
import RevenuesList from '@/components/depot/RevenuesList.vue';
import StocksList from '@/components/depot/StocksList.vue';
import { Getter } from 'vuex-class';

import {Depot, Revenues, Transactions, Stocks} from '@/store/depot/types';

@Component({
  name: 'dashboard-view',
  components: {
    TransactionsList,
    RevenuesList,
    StocksList,
  },
})
export default class DashboardView extends Vue {

  @Getter('depot/transactions') transactions?: Transactions;
  @Getter('depot/revenues') revenues?: Revenues;
  @Getter('depot/stocks') stocks?: Stocks;
  @Getter('depot/loadingDepot') loadingDepot?: Boolean;
  @Getter('depot/depot') depot?: Depot;
}
