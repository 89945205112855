
import { Component, Vue, Prop } from 'vue-property-decorator';
import {Revenue, Revenues, Transaction} from '@/store/depot/types';
import { currency } from '@/filters/currency';
import { dateString } from '@/filters/date';
import ExporoTable from '@/components/_common/Table.vue';
import {getIdToken} from '@/utils/Auth';

@Component({
  name: 'revenues-list',
  filters: {
    currency,
    dateString,
  },
  components: {
    ExporoTable,
  },
})
export default class RevenuesOverviewCard extends Vue {
  @Prop({ default: undefined, required: true }) revenues?: Revenues;
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;

  get tableData(): Array<object> {


    const tableData: Array<object> = [];

    if (this.revenues && this.revenues.data) {
      this.revenues.data
        .map(revenue => {
          tableData.push({
            [ this.$t('transactionsList.dataTable.label', { context: 'date' }) ]: {
              text: this.parseDate(revenue.importDate),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'description' }) ]: {
              text: this.description(revenue),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'usage' }) ]: {
              text: this.purpose(revenue),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'amount' }) ]: {
              text: this.humanReadableAmount(revenue),
            },
          });
        });
    }

    return tableData;
  }

  parseDate(date) {
    return this.$options.filters.dateString(date);
  }

  parseAmount(amount) {
    return this.$options.filters.currency(amount);
  }

  humanReadableAmount(revenue: Revenue) {
    if (revenue.umart === 'STORNO Coupons/Dividende') {
      return '-' + this.parseAmount(revenue.saldo);
    }

    return this.parseAmount(revenue.saldo);
  }

  description(revenue: Revenue) {
    if (revenue.documentId) {
      let href = process.env.VUE_APP_BAADER_DEPOT_API_URL + '/document/' + revenue.documentId + '?jwt=' + getIdToken();

      return '<a href='+ href +'>' + revenue.umart + '<i class="material-icons">description</i></a>';
    }

    return revenue.umart;
  }

  purpose(revenue: Revenue) {
    return revenue.asset !== null ? revenue.asset : revenue.purpose;
  }

  onTableUpdate(val) {
    this.$emit('update', val);
  }
}
