
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Stocks } from '@/store/depot/types';
import { Asset } from '@/store/trading/types';
import { currency } from '@/filters/currency';
import { dateString } from '@/filters/date';
import ExporoTable from '@/components/_common/Table.vue';

@Component({
  name: 'stocks-list',
  filters: {
    currency,
    dateString,
  },
  components: {
    ExporoTable,
  },
})
export default class StocksOverviewCard extends Vue {
  @Prop({ default: undefined, required: true }) stocks?: Stocks;
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;

  @Getter('trading/projects') projects?: Asset[];

  get tableData(): Array<object> {

    const tableData: Array<object> = [];

    if (this.stocks && this.stocks.data) {
      this.stocks.data
        .map(stock => {
          tableData.push({
            [ this.$t('transactionsList.dataTable.label', { context: 'project' }) ]: {
              text: stock.asset,
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'isin' }) ]: {
              text: stock.wpNr,
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'amount' }) ]: {
              text: this.parseAmount(stock.nwm),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'reporting' }) ]: {
              text: this.getProjectDetailPage(stock.wpNr),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'action' }) ]: {
              text: '',
              value: stock.wpNr,
              slotId: `bonds-${stock.wpNr}`,
            },
          });
        });
    }

    return tableData;
  }

  parseAmount(amount) {
    return this.$options.filters.currency(amount);
  }

  onTableUpdate(val) {
    this.$emit('update', val);
  }

  canSellProject(assetId: string) {
    return !!this.projects?.find( asset => asset.asset_id === assetId);
  }

  getFinancialProductByStockId(assetId) {
    const asset = this.projects?.find( asset => asset.asset_id === assetId);

    return !!asset ? asset.financial_product_id : null;
  }

  getProjectDetailPage(pdpId){
    const fpId = this.getFinancialProductByStockId(pdpId);

    return !fpId ? '' : `<a style="text-decoration: underline; color: inherit;"
      href="${process.env.VUE_APP_EXPORO_PDP_URL}/${fpId}/reporting"
      target="_blank">
        ${this.$t('transactionsList.dataTable.label', { context: 'details' })}
      </a>`;
  }
}
