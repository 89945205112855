
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Transactions } from '@/store/depot/types';
import { currency } from '@/filters/currency';
import { dateString } from '@/filters/date';
import ExporoTable from '@/components/_common/Table.vue';

@Component({
  name: 'transactions-list',
  filters: {
    currency,
    dateString,
  },
  components: {
    ExporoTable,
  },
})
export default class TransactionsOverviewCard extends Vue {
  @Prop({ default: undefined, required: true }) transactions?: Transactions;
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;

  get tableData(): Array<object> {


    const tableData: Array<object> = [];

    if (this.transactions && this.transactions.data) {
      this.transactions.data
        .map(transaction => {
          tableData.push({
            [ this.$t('transactionsList.dataTable.label', { context: 'date' }) ]: {
              text: this.parseDate(transaction.importDate),
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'description' }) ]: {
              text: transaction.wpGart,
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'project' }) ]: {
              text: transaction.asset,
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'isin' }) ]: {
              text: transaction.wpNr,
            },
            [ this.$t('transactionsList.dataTable.label', { context: 'amount' }) ]: {
              text: this.parseAmount(transaction.nw),
            },
          });
        });
    }

    return tableData;
  }

  parseDate(date) {
    return this.$options.filters.dateString(date);
  }

  parseAmount(amount) {
    return this.$options.filters.currency(amount);
  }

  onTableUpdate(val) {
    this.$emit('update', val);
  }
}
